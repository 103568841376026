<template>
  <GroupPackages
    v-if="group"
    :group-id="group.id"
    :accommodation-options="accommodations"
  />
</template>

<script>
import GroupPackages from "./GroupPackages/GroupPackages.vue";

export default {
  components: { GroupPackages },
  computed: {
    group() {
      return this.$store.state["app-groups"]?.currentGroup;
    },
    accommodations() {
      return this.$store.state["app-groups"]?.accommodations;
    },
  },
};
</script>
